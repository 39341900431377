import { Button, Flex, Heading, Image, Text, View } from "@aws-amplify/ui-react";
import "@fontsource/raleway/600.css";
import logo from '../../media/EZ-EAP_Horizontal_Blue_White_Primary_Logomark.png';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HeaderBar = ({signOut, user}) => {
    const navigate = useNavigate();
    useEffect(() => {
        //console.log(user)
    }, [])
    return (
        <Flex 
        direction="row"
        width="100%"
        height="unset"
        position="fixed" // Change to fixed
        top="0" // Stick to the top of the viewport
        backgroundColor="#ef233c"
        style={{ borderBottom: "3px solid", borderColor: '#03354c', zIndex: '1000', padding: '10px 32px', justifyContent: 'space-between', alignItems: 'center' }}
        >
            <span
            className="custom-button-wrapper"
            onClick={() => { navigate('/') }}
            style={{
                cursor: 'pointer',  // Ensure cursor is pointer on hover
                display: 'inline-block', // Ensures the span takes up only the space it needs
                outline: 'none' // Remove outline on span
            }}
            >
                <Image width="100px" src={logo} alt="Logo" />
            </span>
            <Flex alignItems="center">
                <Text 
                style={{ fontFamily: 'Helvetica' }}
                color="white"
                fontStyle="italic">
                    {user.signInDetails.loginId}
                </Text>
                <Button onClick={signOut} size="small" style={{color:'white', backgroundColor:"#03354c", borderColor:"#03354c"}}>
                    <Text fontWeight="300" color="white" style={{ fontFamily: 'Helvetica' }}>Sign Out</Text>
                </Button>
            </Flex>
        </Flex>
    )
}

export default HeaderBar;