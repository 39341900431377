import React, {useState} from 'react'
import emailjs from '@emailjs/browser'
import { Alert, Button, Flex, Input, Label, Text, TextField, TextAreaField} from '@aws-amplify/ui-react'
import { Spin } from 'antd';

const SupportForm = () => {
    const [isSuccessAlertVisible, setIsSuccessAlertVisible] = useState(false);
    const [isErrorAlertVisible, setIsErrorAlertVisible] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isNameFocused, setIsNameFocused] = useState(false);
    const [isEmailFocused, setIsEmailFocused] = useState(false);
    const [isMessageFocused, setIsMessageFocused] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validEmail, setValidEmail] = useState(false);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const validateEmail = (e) => {
        setEmail(e.target.value)
        const valid = emailRegex.test(e.target.value)
        console.log(valid)
        setValidEmail(valid)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!validEmail){
            setIsSuccessAlertVisible(false)
            setIsErrorAlertVisible(true)
            return
        }
        setLoading(true)

        const serviceID = "service_48kn4gm"
        const templateID = "template_waw4rzg"
        const publicKey = "T3eExJunVGo6LHHFW"

        const templateParams = {
            from_name: name,
            from_email: email,
            message: message
        }

        emailjs.send(serviceID, templateID, templateParams, publicKey)
            .then((response) => {
                setIsSuccessAlertVisible(true)
                setIsErrorAlertVisible(false)
                setName('')
                setEmail('')
                setMessage('')
                setLoading(false)
            })
            .catch((error) => {
                setIsSuccessAlertVisible(false)
                setIsErrorAlertVisible(true)
                setLoading(false)
            })
    }

    const nameTextFieldStyle = {
        borderColor: isNameFocused ? 'white' :  "#8d99af",
        color: 'white',
        transition: 'border-color 0.3s ease',
        fontFamily: 'Helvetica',
    };

    const emailTextFieldStyle = {
        borderColor: isEmailFocused ? 'white' :  "#8d99af",
        color: 'white',
        transition: 'border-color 0.3s ease',
        fontFamily: 'Helvetica',
    };

    const messageTextFieldStyle = {
        borderColor: isMessageFocused ? 'white' :  "#8d99af",
        color: 'white',
        transition: 'border-color 0.3s ease',
        fontFamily: 'Helvetica',
    };

    return (
        <Flex as="form" direction="column" width="60%" gap="1rem" marginBottom="2rem" style={{marginLeft:"20%"}} onSubmit={handleSubmit}>
            <Text fontWeight="300" style={{fontFamily: 'Helvetica', fontSize: '5vw', textAlign: "center"}} paddingTop="10%" paddingBottom="3%" textAlign="center" color="white">Questions? Contact Us Below!</Text>
            <TextField
            label={<Text color="white" style={{fontFamily: 'Helvetica'}}>Name*</Text>}
            placeholder="Your Name..."
            value={name}
            onChange={(e) => setName(e.target.value)}
            onFocus={() => setIsNameFocused(true)}
            onBlur={() => setIsNameFocused(false)}
            style={nameTextFieldStyle}
            isRequired={true}/>
            <TextField
            label={<Text color="white" style={{fontFamily: 'Helvetica'}}>Email*</Text>}
            placeholder="Your Email..."
            value={email}
            hasError={!validEmail}
            errorMessage={<Text color="#ef233c">Please enter a valid email address</Text>}
            onChange={validateEmail}
            onFocus={() => setIsEmailFocused(true)}
            onBlur={() => setIsEmailFocused(false)}
            style={emailTextFieldStyle}
            isRequired={true}/>
            <TextAreaField
            rows={8}
            label={<Text color="white" style={{fontFamily: 'Helvetica'}}>Message*</Text>}
            placeholder="Your Message..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onFocus={() => setIsMessageFocused(true)}
            onBlur={() => setIsMessageFocused(false)}
            style={messageTextFieldStyle}
            isRequired={true}/>
            <Button size="medium" style={{ backgroundColor: "#8d99af", color: "white", marginTop:'5%' }} type="submit">
                <Text fontWeight="300" color="white" style={{ fontFamily: 'Helvetica' }}>Submit</Text>
                {loading && <Spin style={{marginLeft: '2%', color:'white'}}/>}
            </Button>
            {isSuccessAlertVisible && (
                <Alert 
                    variation="success" 
                    isDismissible={false}
                    closable 
                    afterClose={() => setIsSuccessAlertVisible(false)} 
                >
                    Support request successfully sent!
                </Alert>
            )}
            {isErrorAlertVisible && (
                <Alert
                    variation="error" 
                    isDismissible={false}
                    afterClose={() => setIsErrorAlertVisible(false)} 
                >
                    Error sending support request-- please try again.
                </Alert>
            )}
        </Flex>
    )
}

export default SupportForm