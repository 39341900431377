import { Button, Text } from "@aws-amplify/ui-react";
import { Modal } from 'antd';
import { useState } from 'react';
import { post } from 'aws-amplify/api';
import '../../App.css'

const SubscriptionModal = ({altered, setAltered, cancelled, stripeID}) => {
    const [visible, setVisible] = useState(false);

    const handleButtonClick = () => {
        setVisible(true)
    }

    const handleCancelClick = () => {
        setVisible(false)
    }

    const handleOkClick = async () => {
        const apiName = "stripeapi"
        const path = "/subscriptions"
        const options = {
            body: {
                subscriptionID: stripeID,
                cancelled: cancelled
            }
        }
        const response = post({apiName, path, options})
        setAltered(!altered)
        setVisible(false)
        //console.log(response)
    }

    return (
        <div>
            <Button 
            className="subscription-button"
            onClick={handleButtonClick}
            style={{color: 'white', backgroundColor:(cancelled ? '#24344f' : '#ef233c')}}
            variation="primary">
                <Text style={{fontFamily: 'Helvetica'}}>{cancelled ? "Resume Plan" : "Cancel Plan"}</Text>
            </Button>
            <Modal 
            title={cancelled ? "Resume Plan" : "Cancel Plan"} 
            closable={false}
            open={visible} 
            onOk={handleOkClick}
            onCancel={handleCancelClick}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ style: { backgroundColor: '#03354c', borderColor: '#03354c' } }}>
                <Text>Are you sure that you'd like to {cancelled ? "resume" : "cancel"} the subscription?</Text>                
            </Modal>
        </div>
    )
}

export default SubscriptionModal