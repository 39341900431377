import { Button, Flex, Heading, Image, Text, View } from "@aws-amplify/ui-react";
import "@fontsource/raleway/600.css";
import logo from '../../media/EZ-EAP_Horizontal_Blue_White_Primary_Logomark.png';
import { useNavigate } from "react-router-dom";
import '../../App.css';

const HomeHeaderBar = ({signOut, user}) => {
    const navigate = useNavigate();
    return (
        <Flex 
        direction="row"
        width="100%"
        height="unset"
        position="fixed" // Change to fixed
        top="0" // Stick to the top of the viewport
        backgroundColor="#ef233c"
        style={{ borderBottom: "3px solid", borderColor: '#03354c', zIndex: '1000', padding: '10px 32px', justifyContent: 'space-between', alignItems: 'center' }}
        >
            <span
            className="custom-button-wrapper"
            onClick={() => { navigate('/') }}
            style={{
                cursor: 'pointer',  // Ensure cursor is pointer on hover
                display: 'inline-block', // Ensures the span takes up only the space it needs
                outline: 'none' // Remove outline on span
            }}
            >
                <Image width="100px" src={logo} alt="Logo" />
            </span>
            <Flex alignItems="center">
                {/*<Button size="small" style={{ backgroundColor: "#8d99af", color: "white", borderColor: "#ef233c", marginRight: '10px' }} onClick={() => { navigate('/create-account') }}>
                    <Text fontWeight="300" color="white" style={{ fontFamily: 'Helvetica' }}>Create Account</Text>
                </Button>*/}
                <span
                className="custom-button-wrapper"
                onClick={() => { navigate('/tutorial') }}
                style={{
                    cursor: 'pointer',  // Ensure cursor is pointer on hover
                    display: 'inline-block', // Ensures the span takes up only the space it needs
                    outline: 'none', // Remove outline on span
                }}
                >
                    <Text fontWeight="300" color="white" style={{ fontFamily: 'Helvetica' }}>How to Use</Text>
                </span>
                <Button size="small" style={{ backgroundColor: "#03354c", color: "white", borderColor: "#ef233c" }} onClick={() => { navigate('/subscription') }}>
                    <Text fontWeight="300" color="white" style={{ fontFamily: 'Helvetica' }}>Account Portal</Text>
                </Button>
            </Flex>
        </Flex>
    )
}

export default HomeHeaderBar;