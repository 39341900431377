/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrgMember = /* GraphQL */ `
  mutation CreateOrgMember(
    $input: CreateOrgMemberInput!
    $condition: ModelOrgMemberConditionInput
  ) {
    createOrgMember(input: $input, condition: $condition) {
      id
      accountID
      organizationID
      privilege
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOrgMember = /* GraphQL */ `
  mutation UpdateOrgMember(
    $input: UpdateOrgMemberInput!
    $condition: ModelOrgMemberConditionInput
  ) {
    updateOrgMember(input: $input, condition: $condition) {
      id
      accountID
      organizationID
      privilege
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOrgMember = /* GraphQL */ `
  mutation DeleteOrgMember(
    $input: DeleteOrgMemberInput!
    $condition: ModelOrgMemberConditionInput
  ) {
    deleteOrgMember(input: $input, condition: $condition) {
      id
      accountID
      organizationID
      privilege
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      OrgMembers {
        items {
          id
          accountID
          organizationID
          privilege
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      abbreviation
      primaryLocation
      colorOne
      colorTwo
      colorThree
      EAPs {
        items {
          id
          name
          pdf_location
          address
          contacts {
            name
            position
            phone_number
            __typename
          }
          alert_message
          userEmails
          EAPMembers {
            items {
              id
              eapID
              accountID
              permanent
              temporaryRange
              privilege
              favorited
              emailNotif
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          organizationID
          activated
          activatedAt
          activationType
          creatorIID
          practice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userEmails
      subscriptions
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      OrgMembers {
        items {
          id
          accountID
          organizationID
          privilege
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      abbreviation
      primaryLocation
      colorOne
      colorTwo
      colorThree
      EAPs {
        items {
          id
          name
          pdf_location
          address
          contacts {
            name
            position
            phone_number
            __typename
          }
          alert_message
          userEmails
          EAPMembers {
            items {
              id
              eapID
              accountID
              permanent
              temporaryRange
              privilege
              favorited
              emailNotif
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          organizationID
          activated
          activatedAt
          activationType
          creatorIID
          practice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userEmails
      subscriptions
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      OrgMembers {
        items {
          id
          accountID
          organizationID
          privilege
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      abbreviation
      primaryLocation
      colorOne
      colorTwo
      colorThree
      EAPs {
        items {
          id
          name
          pdf_location
          address
          contacts {
            name
            position
            phone_number
            __typename
          }
          alert_message
          userEmails
          EAPMembers {
            items {
              id
              eapID
              accountID
              permanent
              temporaryRange
              privilege
              favorited
              emailNotif
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          organizationID
          activated
          activatedAt
          activationType
          creatorIID
          practice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userEmails
      subscriptions
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      EAPMembers {
        items {
          id
          eapID
          accountID
          permanent
          temporaryRange
          privilege
          favorited
          emailNotif
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      email
      firstName
      lastName
      pushToken
      OrgMembers {
        items {
          id
          accountID
          organizationID
          privilege
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      stripeID
      subscriptionPlan
      subscriptionEnd
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      EAPMembers {
        items {
          id
          eapID
          accountID
          permanent
          temporaryRange
          privilege
          favorited
          emailNotif
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      email
      firstName
      lastName
      pushToken
      OrgMembers {
        items {
          id
          accountID
          organizationID
          privilege
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      stripeID
      subscriptionPlan
      subscriptionEnd
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      EAPMembers {
        items {
          id
          eapID
          accountID
          permanent
          temporaryRange
          privilege
          favorited
          emailNotif
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      email
      firstName
      lastName
      pushToken
      OrgMembers {
        items {
          id
          accountID
          organizationID
          privilege
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      stripeID
      subscriptionPlan
      subscriptionEnd
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEAPMember = /* GraphQL */ `
  mutation CreateEAPMember(
    $input: CreateEAPMemberInput!
    $condition: ModelEAPMemberConditionInput
  ) {
    createEAPMember(input: $input, condition: $condition) {
      id
      eapID
      accountID
      permanent
      temporaryRange
      privilege
      favorited
      emailNotif
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEAPMember = /* GraphQL */ `
  mutation UpdateEAPMember(
    $input: UpdateEAPMemberInput!
    $condition: ModelEAPMemberConditionInput
  ) {
    updateEAPMember(input: $input, condition: $condition) {
      id
      eapID
      accountID
      permanent
      temporaryRange
      privilege
      favorited
      emailNotif
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEAPMember = /* GraphQL */ `
  mutation DeleteEAPMember(
    $input: DeleteEAPMemberInput!
    $condition: ModelEAPMemberConditionInput
  ) {
    deleteEAPMember(input: $input, condition: $condition) {
      id
      eapID
      accountID
      permanent
      temporaryRange
      privilege
      favorited
      emailNotif
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createEAP = /* GraphQL */ `
  mutation CreateEAP(
    $input: CreateEAPInput!
    $condition: ModelEAPConditionInput
  ) {
    createEAP(input: $input, condition: $condition) {
      id
      name
      pdf_location
      address
      contacts {
        name
        position
        phone_number
        __typename
      }
      alert_message
      userEmails
      EAPMembers {
        items {
          id
          eapID
          accountID
          permanent
          temporaryRange
          privilege
          favorited
          emailNotif
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      organizationID
      activated
      activatedAt
      activationType
      creatorIID
      practice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateEAP = /* GraphQL */ `
  mutation UpdateEAP(
    $input: UpdateEAPInput!
    $condition: ModelEAPConditionInput
  ) {
    updateEAP(input: $input, condition: $condition) {
      id
      name
      pdf_location
      address
      contacts {
        name
        position
        phone_number
        __typename
      }
      alert_message
      userEmails
      EAPMembers {
        items {
          id
          eapID
          accountID
          permanent
          temporaryRange
          privilege
          favorited
          emailNotif
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      organizationID
      activated
      activatedAt
      activationType
      creatorIID
      practice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteEAP = /* GraphQL */ `
  mutation DeleteEAP(
    $input: DeleteEAPInput!
    $condition: ModelEAPConditionInput
  ) {
    deleteEAP(input: $input, condition: $condition) {
      id
      name
      pdf_location
      address
      contacts {
        name
        position
        phone_number
        __typename
      }
      alert_message
      userEmails
      EAPMembers {
        items {
          id
          eapID
          accountID
          permanent
          temporaryRange
          privilege
          favorited
          emailNotif
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      organizationID
      activated
      activatedAt
      activationType
      creatorIID
      practice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest(
    $input: CreateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    createRequest(input: $input, condition: $condition) {
      id
      email
      accountID
      organizationID
      privilege
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRequest = /* GraphQL */ `
  mutation UpdateRequest(
    $input: UpdateRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    updateRequest(input: $input, condition: $condition) {
      id
      email
      accountID
      organizationID
      privilege
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRequest = /* GraphQL */ `
  mutation DeleteRequest(
    $input: DeleteRequestInput!
    $condition: ModelRequestConditionInput
  ) {
    deleteRequest(input: $input, condition: $condition) {
      id
      email
      accountID
      organizationID
      privilege
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createRecord = /* GraphQL */ `
  mutation CreateRecord(
    $input: CreateRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    createRecord(input: $input, condition: $condition) {
      id
      eapID
      accountID
      time
      actionType
      activationType
      practice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateRecord = /* GraphQL */ `
  mutation UpdateRecord(
    $input: UpdateRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    updateRecord(input: $input, condition: $condition) {
      id
      eapID
      accountID
      time
      actionType
      activationType
      practice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteRecord = /* GraphQL */ `
  mutation DeleteRecord(
    $input: DeleteRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    deleteRecord(input: $input, condition: $condition) {
      id
      eapID
      accountID
      time
      actionType
      activationType
      practice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
