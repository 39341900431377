import { Alert, Button, Flex, Text, TextField } from "@aws-amplify/ui-react";
import { Modal, Spin } from 'antd';
import { generateClient, get } from 'aws-amplify/api';
import { useEffect, useState } from "react";
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import SubscriptionTab from "./SubscriptionTab";

const items= [
    {
        title: "Free Plan",
        price: "0",
        points: [
            "No additional organizational creation / EAP creation privileges"
        ]
    },
    {
        title: "Basic Plan",
        price: "price_1P5rBsGbvxfKGylzJDsudqhH",
        points: [
            "$1,500/yr",
            "Allows for creation of organization, in which up to 5 EAPs can be created",
        ]
    },
    {
        title: "Advanced Plan",
        price: "price_1P5rCHGbvxfKGylzjgFkYVbW",
        points: [
            "$1,900/yr",
            "Allows for creation of organization, in which up to 20 EAPs can be created",
        ]
    },
    {
        title: "Premium Plan",
        price: "price_1P5rCfGbvxfKGylzIIsA6AzT",
        points: [
            "$2,200/yr",
            "Allows for creation of organization, in which up to 50 EAPs can be created",
        ]
    }
]

const client = generateClient();

const SubscriptionContainer = ({user}) => {
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [accountPresent, setAccountPresent] = useState(false);
    const [accountID, setAccountID] = useState('');
    const [open, setOpen] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [loading, setLoading] = useState(false);
    const [altered, setAltered] = useState(false);
    const [stripeID, setStripeID] = useState();
    const [currentPlan, setCurrentPlan] = useState();
    const [endDate, setEndDate] = useState();
    const [cancelled, setCancelled] = useState(false);
    const [version, setVersion] = useState();
    const [subscription, setSubscription] = useState();
    async function getAccount() {
        setLoading(true);
        try {
            const account = await client.graphql({
                query: queries.listAccounts,
                variables: {
                    filter: {
                        email: {
                            eq: user.signInDetails.loginId.toLowerCase()
                        }
                    }
                }
            })
            if(account.data.listAccounts.items.length > 0){
                setAccountPresent(true);
                setVersion(account.data.listAccounts.items[0]["_version"]);
                setAccountID(account.data.listAccounts.items[0].id);
                //console.log(account.data.listAccounts.items[0])
                if(account.data.listAccounts.items[0].firstName?.length > 0 && account.data.listAccounts.items[0].lastName?.length > 0) {
                    setStripeID(account.data.listAccounts.items[0].stripeID || null)
                    setCurrentPlan(account.data.listAccounts.items[0].subscriptionPlan || null);
                    setEndDate(account.data.listAccounts.items[0].subscriptionEnd || null);
                    if(account.data.listAccounts.items[0].stripeID){
                        //console.log(account.data.listAccounts.items[0].stripeID)
                        const subscription = await checkSubscription(account.data.listAccounts.items[0].stripeID)
                    }
                    else{
                        setLoading(false);
                    }
                }
                else{
                    setOpen(true);
                    setLoading(false);
                }
            }
            else {
                setOpen(true);
                setLoading(false);
            }
        } catch (error) {
            console.error(error)
        }
    }

    const checkSubscription = async (id) => {
        try {
            //console.log(id)
            const apiName = "stripeapi"
            const path = "/subscriptions/" + id
            const operation = get({apiName, path})
            const response = await operation.response
            const body = await response.body.json()
            if(response){
                setSubscription(body.subscription)
                setCancelled(body.subscription.cancel_at_period_end)
                setLoading(false)
            }
            console.log(body)
        } catch (error) {
            console.log(error)
            getAccount()
        }
    }

    const onOk = async () => {
        if(firstName.length === 0 || lastName.length === 0){
            setIsAlertVisible(true);
            return;
        }
        if(accountPresent){
            const updateInput = {
                "id": accountID,
                "firstName": firstName,
                "lastName": lastName,
                "_version": version
            }
            //console.log(updateInput)
            setOpen(false);
            await client.graphql({
                query: mutations.updateAccount,
                variables: {
                    input: updateInput
                }
            }).then(() => {
                getAccount()
            })
        }
        else{
            const createInput = {
                "email" : user.signInDetails.loginId.toLowerCase(),
                "firstName": firstName,
                "lastName": lastName,
                "subscriptionPlan": "Free Plan"
            }
            setOpen(false);
            await client.graphql({
                query: mutations.createAccount,
                variables: {
                    input: createInput
                }
            }).then(() => {
                getAccount()
            })
        }
    }

    useEffect(() => {
        //console.log(user)
        getAccount()
    }, [altered])

    return (
        <div className="subscription-container">
            {/*<stripe-pricing-table 
                customer-email={user.signInDetails.loginId.toLowerCase()}
                cancel-url='https://ez-eap.com/subscription'
                consent-collection={{
                    terms_of_service: 'required',
                }}
                pricing-table-id="prctbl_1Pj23jGbvxfKGylzK4pbs6z7"
                publishable-key="pk_live_51NXXIMGbvxfKGylzC786Vizpx946fmkUPC63tYQMxPx2B25xpc8qff0PaPBLCTOcLxL8Vr5A1bq4Tjih2WBWrtZe00YE9ZXf8H"
            >
            </stripe-pricing-table>*/}
            {loading ? (
                <Spin />
            ) : (
                items.map((item, index) => (
                    <SubscriptionTab 
                        key={index}
                        cancelled={cancelled}
                        endDate={endDate}
                        current={currentPlan}
                        price={item.price}
                        stripeID={stripeID}
                        title={item.title}
                        points={item.points}
                        user={user}
                        altered={altered}
                        setAltered={setAltered}
                        loading={loading}
                        setLoading={setLoading}
                        subscription={subscription}
                    />
                ))
            )}
            <Modal
                title="Complete Your Profile"
                closable={false}
                open={open}
                onOk={onOk}
                okText="Submit"
                okButtonProps={{ style: { backgroundColor: '#03354c', borderColor: '#03354c' } }}
                footer={(_, { OkBtn }) => (
                    <>
                      <OkBtn style={{backgroundColor:"red"}}/>
                    </>
                  )}
            >
                <TextField 
                    label="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField 
                    label="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                {isAlertVisible && (
                    <Alert 
                        message="Please fill in all fields" 
                        type="error" 
                        closable 
                        afterClose={() => setIsAlertVisible(false)} 
                    />
                )}
            </Modal>
        </div>
    );
}

export default SubscriptionContainer;
//https://billing.stripe.com/p/login/5kA02b7hec3ya7m144