/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrgMember = /* GraphQL */ `
  query GetOrgMember($id: ID!) {
    getOrgMember(id: $id) {
      id
      accountID
      organizationID
      privilege
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOrgMembers = /* GraphQL */ `
  query ListOrgMembers(
    $filter: ModelOrgMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountID
        organizationID
        privilege
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrgMembers = /* GraphQL */ `
  query SyncOrgMembers(
    $filter: ModelOrgMemberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrgMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        accountID
        organizationID
        privilege
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const orgMembersByAccountID = /* GraphQL */ `
  query OrgMembersByAccountID(
    $accountID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orgMembersByAccountID(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        organizationID
        privilege
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const orgMembersByOrganizationID = /* GraphQL */ `
  query OrgMembersByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrgMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orgMembersByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountID
        organizationID
        privilege
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      OrgMembers {
        items {
          id
          accountID
          organizationID
          privilege
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      name
      abbreviation
      primaryLocation
      colorOne
      colorTwo
      colorThree
      EAPs {
        items {
          id
          name
          pdf_location
          address
          contacts {
            name
            position
            phone_number
            __typename
          }
          alert_message
          userEmails
          EAPMembers {
            items {
              id
              eapID
              accountID
              permanent
              temporaryRange
              privilege
              favorited
              emailNotif
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          organizationID
          activated
          activatedAt
          activationType
          creatorIID
          practice
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      userEmails
      subscriptions
      type
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        OrgMembers {
          items {
            id
            accountID
            organizationID
            privilege
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        name
        abbreviation
        primaryLocation
        colorOne
        colorTwo
        colorThree
        EAPs {
          items {
            id
            name
            pdf_location
            address
            contacts {
              name
              position
              phone_number
              __typename
            }
            alert_message
            userEmails
            EAPMembers {
              nextToken
              startedAt
              __typename
            }
            organizationID
            activated
            activatedAt
            activationType
            creatorIID
            practice
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userEmails
        subscriptions
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrganizations = /* GraphQL */ `
  query SyncOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        OrgMembers {
          items {
            id
            accountID
            organizationID
            privilege
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        name
        abbreviation
        primaryLocation
        colorOne
        colorTwo
        colorThree
        EAPs {
          items {
            id
            name
            pdf_location
            address
            contacts {
              name
              position
              phone_number
              __typename
            }
            alert_message
            userEmails
            EAPMembers {
              nextToken
              startedAt
              __typename
            }
            organizationID
            activated
            activatedAt
            activationType
            creatorIID
            practice
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userEmails
        subscriptions
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const organizationsByName = /* GraphQL */ `
  query OrganizationsByName(
    $type: String!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationsByName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        OrgMembers {
          items {
            id
            accountID
            organizationID
            privilege
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        name
        abbreviation
        primaryLocation
        colorOne
        colorTwo
        colorThree
        EAPs {
          items {
            id
            name
            pdf_location
            address
            contacts {
              name
              position
              phone_number
              __typename
            }
            alert_message
            userEmails
            EAPMembers {
              nextToken
              startedAt
              __typename
            }
            organizationID
            activated
            activatedAt
            activationType
            creatorIID
            practice
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        userEmails
        subscriptions
        type
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      EAPMembers {
        items {
          id
          eapID
          accountID
          permanent
          temporaryRange
          privilege
          favorited
          emailNotif
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      email
      firstName
      lastName
      pushToken
      OrgMembers {
        items {
          id
          accountID
          organizationID
          privilege
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      stripeID
      subscriptionPlan
      subscriptionEnd
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        EAPMembers {
          items {
            id
            eapID
            accountID
            permanent
            temporaryRange
            privilege
            favorited
            emailNotif
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        email
        firstName
        lastName
        pushToken
        OrgMembers {
          items {
            id
            accountID
            organizationID
            privilege
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        stripeID
        subscriptionPlan
        subscriptionEnd
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        EAPMembers {
          items {
            id
            eapID
            accountID
            permanent
            temporaryRange
            privilege
            favorited
            emailNotif
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        email
        firstName
        lastName
        pushToken
        OrgMembers {
          items {
            id
            accountID
            organizationID
            privilege
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        stripeID
        subscriptionPlan
        subscriptionEnd
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEAPMember = /* GraphQL */ `
  query GetEAPMember($id: ID!) {
    getEAPMember(id: $id) {
      id
      eapID
      accountID
      permanent
      temporaryRange
      privilege
      favorited
      emailNotif
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEAPMembers = /* GraphQL */ `
  query ListEAPMembers(
    $filter: ModelEAPMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEAPMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eapID
        accountID
        permanent
        temporaryRange
        privilege
        favorited
        emailNotif
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEAPMembers = /* GraphQL */ `
  query SyncEAPMembers(
    $filter: ModelEAPMemberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEAPMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        eapID
        accountID
        permanent
        temporaryRange
        privilege
        favorited
        emailNotif
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const eAPMembersByEapID = /* GraphQL */ `
  query EAPMembersByEapID(
    $eapID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEAPMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eAPMembersByEapID(
      eapID: $eapID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eapID
        accountID
        permanent
        temporaryRange
        privilege
        favorited
        emailNotif
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const eAPMembersByAccountID = /* GraphQL */ `
  query EAPMembersByAccountID(
    $accountID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEAPMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eAPMembersByAccountID(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eapID
        accountID
        permanent
        temporaryRange
        privilege
        favorited
        emailNotif
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEAP = /* GraphQL */ `
  query GetEAP($id: ID!) {
    getEAP(id: $id) {
      id
      name
      pdf_location
      address
      contacts {
        name
        position
        phone_number
        __typename
      }
      alert_message
      userEmails
      EAPMembers {
        items {
          id
          eapID
          accountID
          permanent
          temporaryRange
          privilege
          favorited
          emailNotif
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      organizationID
      activated
      activatedAt
      activationType
      creatorIID
      practice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listEAPS = /* GraphQL */ `
  query ListEAPS(
    $filter: ModelEAPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEAPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        pdf_location
        address
        contacts {
          name
          position
          phone_number
          __typename
        }
        alert_message
        userEmails
        EAPMembers {
          items {
            id
            eapID
            accountID
            permanent
            temporaryRange
            privilege
            favorited
            emailNotif
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        organizationID
        activated
        activatedAt
        activationType
        creatorIID
        practice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncEAPS = /* GraphQL */ `
  query SyncEAPS(
    $filter: ModelEAPFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEAPS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        pdf_location
        address
        contacts {
          name
          position
          phone_number
          __typename
        }
        alert_message
        userEmails
        EAPMembers {
          items {
            id
            eapID
            accountID
            permanent
            temporaryRange
            privilege
            favorited
            emailNotif
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        organizationID
        activated
        activatedAt
        activationType
        creatorIID
        practice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const eAPSByOrganizationID = /* GraphQL */ `
  query EAPSByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEAPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eAPSByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        pdf_location
        address
        contacts {
          name
          position
          phone_number
          __typename
        }
        alert_message
        userEmails
        EAPMembers {
          items {
            id
            eapID
            accountID
            permanent
            temporaryRange
            privilege
            favorited
            emailNotif
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        organizationID
        activated
        activatedAt
        activationType
        creatorIID
        practice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      email
      accountID
      organizationID
      privilege
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRequests = /* GraphQL */ `
  query ListRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        accountID
        organizationID
        privilege
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRequests = /* GraphQL */ `
  query SyncRequests(
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        email
        accountID
        organizationID
        privilege
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const requestsByAccountID = /* GraphQL */ `
  query RequestsByAccountID(
    $accountID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    requestsByAccountID(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        accountID
        organizationID
        privilege
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const requestsByOrganizationID = /* GraphQL */ `
  query RequestsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    requestsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        accountID
        organizationID
        privilege
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRecord = /* GraphQL */ `
  query GetRecord($id: ID!) {
    getRecord(id: $id) {
      id
      eapID
      accountID
      time
      actionType
      activationType
      practice
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRecords = /* GraphQL */ `
  query ListRecords(
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eapID
        accountID
        time
        actionType
        activationType
        practice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRecords = /* GraphQL */ `
  query SyncRecords(
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        eapID
        accountID
        time
        actionType
        activationType
        practice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordsByEapIDAndTime = /* GraphQL */ `
  query RecordsByEapIDAndTime(
    $eapID: ID!
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsByEapIDAndTime(
      eapID: $eapID
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eapID
        accountID
        time
        actionType
        activationType
        practice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordsByAccountID = /* GraphQL */ `
  query RecordsByAccountID(
    $accountID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsByAccountID(
      accountID: $accountID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eapID
        accountID
        time
        actionType
        activationType
        practice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const recordsByActionType = /* GraphQL */ `
  query RecordsByActionType(
    $actionType: Action!
    $sortDirection: ModelSortDirection
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recordsByActionType(
      actionType: $actionType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eapID
        accountID
        time
        actionType
        activationType
        practice
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
