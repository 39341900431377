import { Button, Card, CheckboxField, Flex, Heading, Text } from "@aws-amplify/ui-react";
import { loadStripe } from "@stripe/stripe-js";
import { get, post } from 'aws-amplify/api';
import { useEffect, useState } from "react";
import { MdCheckCircleOutline } from "react-icons/md";
import SubscriptionModal from "./SubscriptionModal";
import { Modal } from 'antd';
import '../../App.css'
//price_1NXmkHGbvxfKGylzNJ55nsRH
//pk_test_51NXXIMGbvxfKGylzGaCKTaenNVLOtqyprJYLB9AConIs0ur9ttCPY7ooEhQ44UbVVFxs7Fw5zbLVpyY5C5y0UerY00Gsfs4iOz
const SubscriptionTab = ({cancelled, endDate, current, price, stripeID, title, points, user, altered, setAltered, loading, setLoading, subscription}) => {
    const [result, setResult] = useState({});
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [portalURL, setPortalURL] = useState("https://billing.stripe.com/p/login/5kA02b7hec3ya7m144?prefilled_email=" + encodeURIComponent(user.signInDetails.loginId.toLowerCase()))

    /*const handleCancelClick = async () => {
        const body = {
            subscriptionID: stripeID,
            cancelled: cancelled
        }
        const response = await API.post("stripeapi", "/subscriptions", {body})
        setAltered(!altered)
        console.log(response)
    }*/

    const handleCheckboxClick = (e) => {
        setChecked(e.target.checked)
    }

    const onOk = async (e) => {
        e.preventDefault()
        if(!checked){
            setHasError(true)
        }
        else{
            setHasError(false)
            const apiName = "stripeapi"
            const path = "/checkout"
            const options = {
                body: {
                    price: price,
                    customerEmail: user.signInDetails.loginId.toLowerCase(),
                }
            }
            const operation = post({apiName, path, options})
            const {body} = await operation.response;
            const session = await body.json();
            console.log(session)
            const stripe = await loadStripe(
                'pk_live_51NXXIMGbvxfKGylzC786Vizpx946fmkUPC63tYQMxPx2B25xpc8qff0PaPBLCTOcLxL8Vr5A1bq4Tjih2WBWrtZe00YE9ZXf8H'
            )
            const res = await stripe.redirectToCheckout({
                sessionId: session.id,
            })
            setResult(res)
        }
    }

    const onCancel = () => {
        setOpen(false)
    }

    const handleChooseClick = async () => {
        setLoading(true)
        const apiName = "stripeapi"
        const path = "/checkout"
        const options = {
            body: {
                price: price,
                customerEmail: user.signInDetails.loginId.toLowerCase(),
            }
        }
        const operation = post({apiName, path, options})
        const {body} = await operation.response;
        const session = await body.json();
        console.log(session)
        const stripe = await loadStripe(
            'pk_live_51NXXIMGbvxfKGylzC786Vizpx946fmkUPC63tYQMxPx2B25xpc8qff0PaPBLCTOcLxL8Vr5A1bq4Tjih2WBWrtZe00YE9ZXf8H'
        )
        const res = await stripe.redirectToCheckout({
            sessionId: session.id,
        })
        setResult(res)
    }

    const checkSubscription = async () => {
        const apiName = "stripeapi"
        const path = "/subscriptions"
        const options = {
            body: {
                subscriptionID: stripeID,
            }
        }

        const response = get({apiName, path, options})
        console.log(response)
    }

    useEffect(() => {
    }, [])

    return (
        <Flex
        className="subscription-tab"
        direction="column"
        justifyContent="start">
            <Modal 
            open={open} 
            closable={false} 
            onCancel={onCancel} 
            onOk={onOk} 
            okButtonProps={{ style: { backgroundColor: '#03354c', borderColor: '#03354c' } }}
            okText="Continue"
            >
                <CheckboxField
                    errorMessage={<Text color='#ef233c'>Please agree to the customer agreement.</Text>}
                    hasError={hasError}
                    checked={checked}
                    onChange={handleCheckboxClick}
                    name="customerAgreement"
                    value="yes"
                    label={
                        <span>
                        You must agree to the <a style={{color: '#ef233c'}} href="https://ezeap-storage-869a2ccd155713-staging.s3.amazonaws.com/public/EZ-EAP-Customer-Agreement-%28CJS-7.24.24%29---ELECTRONIC-RECORD.docx.pdf" target="_blank" rel="noopener noreferrer">Customer Agreement</a> to advance to checkout.
                        </span>
                    }
                />
            </Modal>
            <Card
            className="subscription-card"
            alignItems="center"
            backgroundColor="#F5F5F5" 
            justifyContent="start"
            textAlign="center"
            variation="elevated"
            >
                <Flex direction="row" justifyContent="center" alignItems="center">
                    <Heading fontWeight="600" style={{fontFamily: 'Helvetica'}} level={4} color="black">{title}</Heading>
                    {current === title && <MdCheckCircleOutline color="green" size={28}/>}
                </Flex>
                {title !== "Free Plan" && <Text style={{color: '#ef233c', fontFamily: 'Helvetica', fontStyle: 'italic'}}>Enterprise</Text>}
                {points.map((point) => (
                    <Text 
                    fontWeight="300"
                    style={{fontFamily: 'Helvetica'}}
                    color="black"
                    paddingTop="5%"
                    textAlign="center">
                        - {point}
                    </Text>
                ))}
            </Card>
            {title !== "Free Plan" && current === title &&
                <Button 
                className="freetrial-button"
                variation="primary">
                    <a href={portalURL}><Text style={{ fontFamily: 'Helvetica' }}>Manage Subscription</Text></a>
                </Button>
            }
            {title !== "Free Plan" && current === "Free Plan" &&
            <>
                <Button 
                className="freetrial-button"
                onClick={handleChooseClick}
                variation="primary">
                    <Text style={{fontFamily: 'Helvetica'}}>Start Free Trial</Text>
                </Button>
                <Button 
                className="subscription-button"
                onClick={handleChooseClick}
                variation="primary">
                    <Text style={{fontFamily: 'Helvetica'}}>Subscribe</Text>
                </Button>
            </>
            }
            {/*current === title && subscription?.status === "trialing" && (
                    <Text
                    color="black"
                    fontWeight="bold"
                    fontStyle="italic"
                    paddingTop="2%"
                    textAlign="start">
                        Free trial set to end on {new Date(subscription.trial_end*1000).toLocaleString()}
                    </Text>
            )}
            {current === title && cancelled && (
                    <Text
                    color="black"
                    fontWeight="bold"
                    fontStyle="italic"
                    paddingTop="5%"
                    textAlign="start">
                        Subscription set to be cancelled on {new Date(endDate*1000).toLocaleString()}
                    </Text>
            )*/}
        </Flex>
    )
}

export default SubscriptionTab;