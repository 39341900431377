import {Authenticator, Button, CheckboxField, defaultDarkModeOverride, Divider, Heading, Image, Text, TextField, useAuthenticator, useTheme, ThemeProvider, View, Flex} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import '@fontsource/inter';
import { useState } from 'react';
import HeaderBar from '../components/subscription/HeaderBar';
import SubscriptionContainer from '../components/subscription/SubscriptionContainer';
import { useNavigate } from "react-router-dom";
import '../App.css';
import logo from '../media/EZ-EAP_Horizontal_Blue_Black_Primary_Logomark.png';
//import * as mutations from '../graphql/mutations';
//import { API, Auth, graphqlOperation } from 'aws-amplify';
//import {BrowserRouter as Router} from 'react-router-dom';

const CreateAccountPage = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [colorMode, setColorMode] = useState('system')
    const {tokens} = useTheme()
    const components = {
        Header() {
            const { tokens } = useTheme();
        
            return (
            <View textAlign="center" padding={tokens.space.large}>
                <Image width="200px" src={logo}/>
            </View>
            );
        },
        Footer() {
            const navigate = useNavigate();
            const { tokens } = useTheme();
        
            return (
            <View textAlign="center" padding={tokens.space.large}>
                <Flex style={{paddingTop:'1%', justifyContent: "center"}}>
                    <Button size="large" style={{backgroundColor: "#ef233c", color:"white", borderColor:"#ef233c"}} onClick={() => {navigate('/')}}><Text fontWeight="300" style={{fontFamily: 'Helvetica'}}>Return to Home</Text></Button>
                </Flex>
                <Text style={{paddingTop: '5%'}} color={tokens.colors.neutral[80]}>
                &copy; Next-Gen Emergency Solutions LLC
                </Text>
            </View>
            );
        },
        SignUp: {
            FormFields() {
                const { validationErrors } = useAuthenticator();
                return (
                <>
                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                    <Authenticator.SignUp.FormFields />
                    {/* Append & require Terms and Conditions field to sign up  */}
                    <CheckboxField
                    errorMessage={validationErrors.terms}
                    hasError={!!validationErrors.terms}
                    name="terms"
                    value="yes"
                    label={
                        <span>
                        By checking here, I state that I have read, understand, and agree to the <a style={{color: '#ef233c'}} href="https://ezeap-storage-869a2ccd155713-staging.s3.amazonaws.com/public/Next-Gen-Emergency-Solutions-LLC---Terms-of-Service-(CJS-8.9.23).docx.pdf" target="_blank" rel="noopener noreferrer">Terms of Service</a>.
                        </span>
                    }
                    />
                    <CheckboxField
                    errorMessage={validationErrors.acknowledgement}
                    hasError={!!validationErrors.acknowledgement}
                    name="acknowledgement"
                    value="yes"
                    label={
                        <span>
                        By checking here, I state that I have read, understand, and agree to the <a style={{color: '#ef233c'}} href="https://ezeap-storage-869a2ccd155713-staging.s3.amazonaws.com/public/Next-Gen-Emergency-Solutions-LLC---Privacy-Policy-(CJS-8.9.23).docx.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                        </span>
                    }
                    />
                    <CheckboxField
                    errorMessage={validationErrors.statementCheck}
                    hasError={!!validationErrors.statementCheck}
                    name="statementCheck"
                    value="yes"
                    label="By checking here, I state that I have read, understand, and agree to the below statements:"
                    />
                    <div style={{padding: 20}}>
                        <Text style={{fontSize:12}}>
                        - EZ-EAP utilizes SMS messaging as well as cellular voice calls; standard text messaging rates or other carrier charges may apply. 
                        </Text>
                        <Text style={{fontSize:12, paddingTop: 15}}>
                        - EZ-EAP relies on network connection. A slow or unreliable connection may result in longer loading times or decreased functionality. Standard data rates may apply. 
                        </Text>
                        <Text style={{fontSize:12, paddingTop: 15}}>
                        - EZ-EAP is meant to supplement current emergency action plan procedures, not replace them. No current procedures should be omitted due to the implementation of EZ-EAP. 
                        </Text>
                    </div>
                </>
                );
            }
        }
    };
    const theme = {
        name: 'my-theme',
        overrides: [defaultDarkModeOverride],
        tokens: {
            colors: {
                background: {
                    primary: {
                        value: "#03354c"
                    },
                    secondary: {
                        value: "#03354c"
                    }
                },
                font: {
                    interactive: {
                        value: tokens.colors.white.value,
                    },
                    primary: {
                        value: 'white',
                    },
                    secondary: {
                        value: "white",
                    }
                },
                brand: {
                    primary: {
                        '10': 'white',
                        '80': "#ef233c",
                        '90': "#ef233c",
                        '100': "#ef233c",
                    },
                },
            }
        }
    };
    const services = {
        async validateCustomSignUp(formData) {
            //console.log(formData)
            if (!formData.terms) {
                return {
                  terms: <span style={{ color: 'white', fontStyle: 'italic' }}>You must agree to the Terms of Service.</span>,
                };
            }
            if (!formData.acknowledgement) {
              return {
                acknowledgement: <span style={{ color: 'white', fontStyle: 'italic' }}>You must agree to the Privacy Policy.</span>,
              };
            }
            if (!formData.statementCheck) {
                return {
                  statementCheck: <span style={{ color: 'white', fontStyle: 'italic' }}>This is a required field.</span>,
                };
            }
        },
    }
    return (
        <ThemeProvider theme={theme}>
            <Authenticator initialState="signUp" ideSignUp={false} components={components} services={services}>
                {({signOut, user}) => (<View>
                <HeaderBar user={user} signOut={signOut}/>
                <SubscriptionContainer user={user}/>
                <Divider alignItems="center" marginBottom="1%" marginLeft="25%" marginTop="3%" orientation="horizontal" width="50%"/>
                <Text fontWeight="300" style={{fontFamily: 'Helvetica'}} textAlign="center" color="black">Questions? Contact Us:<Text color="black" fontStyle="italic">nextgenemergencysolutionsllc@gmail.com</Text></Text>
                </View>)}
            </Authenticator>
        </ThemeProvider>
    )
}
export default CreateAccountPage;