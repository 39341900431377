import { Button, Divider, Flex, Heading, Image, Text } from "@aws-amplify/ui-react";
import HomeHeaderBar from "../components/home/HomeHeader";
import { useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player'
import { useEffect } from "react";
import ActivateVideo from "../media/VideoActivate2.mov"
import ShareVideo from "../media/VideoShare2.mov"
import StoreVideo from "../media/VideoStore2.mov"
import ArticlePic from "../media/SBAPDarker.png"
import store from "../media/store.png"
import activate from "../media/activate.png"
import share from "../media/share.png"
import CreateAccountVideo from "../media/CreateAccountVideo.mov"
import ChooseSubscription from "../media/ChooseSubscription2.png"
import Step3 from "../media/Step3Pic.png"
import Step4 from "../media/Step4Image.png"
import Step5 from "../media/Step5Pic.png"
import Step6 from "../media/Step6Image.png"
import Step7 from "../media/Step7Pic.png"
import TutorialPic from '../media/SBTP.png'
import { TbCircleNumber1 } from "react-icons/tb";
import { PiNumberCircleFive, PiNumberCircleFour, PiNumberCircleOne, PiNumberCircleSeven, PiNumberCircleSix, PiNumberCircleThree, PiNumberCircleTwo } from "react-icons/pi";

const TutorialPage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <HomeHeaderBar/>
            <Flex position="relative" direction="column" width="100%" justifyContent="center" alignItems="center">
                <Image width="100%" src={TutorialPic} />
                <Text
                    position="absolute"
                    color="white"
                    fontWeight="300"
                    style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '7vw', marginBottom: '20vw' }}
                >
                    How to Use
                </Text>
                <Text
                    position="absolute"
                    color="white"
                    fontWeight="300"
                    style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '3vw', marginTop: '10vw' }}
                >
                    This page details how to get the most out of EZ-EAP with a step-by-step process.
                </Text>
            </Flex>
            <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding="5%">
                <ReactPlayer width="80%" muted="true" controls="true" url={CreateAccountVideo}/>
                <Flex width="75%" direction="column">
                    <Flex style={{alignItems: 'center', justifyContent:'center'}}>
                        <PiNumberCircleOne size={'4vw'} color="#03354c"/>
                        <Text fontWeight="500" color="#03354c" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '4vw' }}>Create an Account</Text>
                    </Flex>
                    <Text fontWeight="300" color="#03354c" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        Using the top right 'Account Portal' button, create an EZ-EAP account by providing an e-mail and password and agreeing to the attached documents and clauses.  
                        Following account confirmation, you will be brought to the account portal.  This is where you can subscribe to an EZ-EAP plan.
                    </Text>
                </Flex>
            </Flex>
            <Flex
            backgroundColor="#8d99af"
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding="5%">
                <Flex width="75%" direction="column">
                    <Flex style={{alignItems: 'center', justifyContent:'center'}}>
                        <PiNumberCircleTwo size={'4vw'} color="white"/>
                        <Text fontWeight="500" color="white" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '4vw' }}>Choose a Subscription Plan</Text>
                    </Flex>
                    <Text fontWeight="300" color="white" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        Once in the account portal, you will have the ability to select a subscription plan for your account.
                        If you are the enterprise representative for your organization, select the Basic, Advanced, or Premium Plan for your account depending on what is most fitting for your organization.
                        Following this, you will be prompted to enter payment information.
                        {/*If you are a standard user of EZ-EAP, no need to worry about purchasing a subscription plan!  You will be added to your respective organizaiton in the app by the enterprise representative.*/}
                    </Text>
                </Flex>
                <Image style={{marginLeft: '2%'}} width="31%" src={ChooseSubscription} />
            </Flex>
            <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding="5%">
                <Image width="20%" src={Step3} />
                <Flex width="75%" direction="column">
                    <Flex style={{alignItems: 'center', justifyContent:'center'}}>
                        <PiNumberCircleThree size={'4vw'} color="#03354c"/>
                        <Text fontWeight="500" color="#03354c" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '4vw' }}>Create an Organization</Text>
                    </Flex>
                    <Text fontWeight="300" color="#03354c" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        After subscribing to a plan, you will now be able to create an organization within EZ-EAP.
                        To do this, press the 'Create Organization' button within the Organization menu.
                        In the creation menu, you will be able to specify the name, abbreviation, and color scheme of the organization, as well as the organization's members and their associated privileges.
                        To add a member to the organization, they must have an EZ-EAP account.
                    </Text>
                </Flex>
            </Flex>
            <Flex
            backgroundColor="#8d99af"
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding="5%">
                <Flex width="75%" direction="column">
                    <Flex style={{alignItems: 'center', justifyContent:'center'}}>
                        <PiNumberCircleFour size={'4vw'} color="white"/>
                        <Text fontWeight="500" color="white" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '4vw' }}>Upload EAPs</Text>
                    </Flex>
                    <Text fontWeight="300" color="white" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        To upload your organization's EAPs, access your organization in the Organization menu and click on the 'Create EAP' button.
                        In the EAP creation menu, you will be able to specify the name, location, alert message, and PDF of the EAP.
                        Additionally, you will be able to specify non-organizational contacts and members that should have access to the EAP, with the ability to clarify permanent or temporary access.
                        {/*If you are a standard user of EZ-EAP, no need to worry about purchasing a subscription plan!  You will be added to your respective organizaiton in the app by the enterprise representative.*/}
                    </Text>
                </Flex>
                <Image width="20%" src={Step4} />
            </Flex>
            <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding="5%">
                <Image width="20%" src={Step5} />
                <Flex width="75%" direction="column">
                    <Flex style={{alignItems: 'center', justifyContent:'center'}}>
                        <PiNumberCircleFive size={'4vw'} color="#03354c"/>
                        <Text fontWeight="500" color="#03354c" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '4vw' }}>Activate an EAP</Text>
                    </Flex>
                    <Text fontWeight="300" color="#03354c" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        Now that an EAP has been created, it is important to know how to activate when an emergency occurs.
                        To do this, click on an EAP in the EAP menu and then press the red "Activate EAP" button.
                        From here, you will be able to specify the context of the emergency and whether or not to call 911.
                        All relevant members and contacts will then receive notifications of the EAP's activation.
                    </Text>
                </Flex>
            </Flex>
            <Flex
            backgroundColor="#8D99AF"
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding="5%">
                <Flex width="75%" direction="column">
                    <Flex style={{alignItems: 'center', justifyContent:'center'}}>
                        <PiNumberCircleSix size={'4vw'} color="white"/>
                        <Text fontWeight="500" color="white" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '4vw' }}>Deactivate an EAP</Text>
                    </Flex>
                    <Text fontWeight="300" color="white" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        Deactivation of an EAP mirrors the activation process.  To access an activated EAP that you wish to deactivate, go to the Activated EAP menu and click on the desired EAP.
                        Then, click on the red "Deactivate EAP" button.  This will deactivate the EAP and notify all relevant members and contacts that the EAP has been deactivated.
                        {/*If you are a standard user of EZ-EAP, no need to worry about purchasing a subscription plan!  You will be added to your respective organizaiton in the app by the enterprise representative.*/}
                    </Text>
                </Flex>
                <Image width="20%" src={Step6} />
            </Flex>
            <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding="5%">
                <Image width="20%" src={Step7} />
                <Flex width="75%" direction="column">
                    <Flex style={{alignItems: 'center', justifyContent:'center'}}>
                        <PiNumberCircleSeven size={'4vw'} color="#03354c"/>
                        <Text fontWeight="500" color="#03354c" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '4vw' }}>Edit EAP Information</Text>
                    </Flex>
                    <Text fontWeight="300" color="#03354c" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        After creation of an EAP, there are likely pieces of it that will require editing in the future.  To do this, click on the EAP in the EAP menu and then click on the gray "EAP Options" button.
                        From here, you can:
                    </Text>
                    <Text fontWeight="300" color="#03354c" style={{ fontStyle:'italic', fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        -Edit the alert message sent to users upon activation.
                    </Text>
                    <Text fontWeight="300" color="#03354c" style={{ fontStyle:'italic', fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        -Edit the contacts that receive text notifcations upon activation.
                    </Text>
                    <Text fontWeight="300" color="#03354c" style={{ fontStyle:'italic', fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        -Edit the users with admin privileges for the EAP.
                    </Text>
                    <Text fontWeight="300" color="#03354c" style={{ fontStyle:'italic', fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        -Edit the users with viewer privileges for the EAP.
                    </Text>
                    <Text fontWeight="300" color="#03354c" style={{ fontStyle:'italic', fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        -Favorite the EAP and add it to the Favorited List in the main EAP menu.
                    </Text>
                    <Text fontWeight="300" color="#03354c" style={{ fontStyle:'italic', fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>
                        -Enable e-mail notifications upon EAP activation and de-activation.
                    </Text>
                </Flex>
            </Flex>
        </div>
    )
}
export default TutorialPage