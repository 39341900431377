import { Button, Divider, Flex, Heading, Image, Text } from "@aws-amplify/ui-react";
import HomeHeaderBar from "../components/home/HomeHeader";
import { useNavigate } from "react-router-dom";
import ReactPlayer from 'react-player'
import { useEffect } from "react";
import ActivateVideo from "../media/VideoActivate2.mov"
import ShareVideo from "../media/VideoShare2.mov"
import StoreVideo from "../media/VideoStore2.mov"
import ArticlePic from "../media/SBAPDarker.png"
import store from "../media/store.png"
import activate from "../media/activate.png"
import share from "../media/share.png"
import SupportForm from "../components/home/SupportForm";

const HomePage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <HomeHeaderBar/>
            <Flex position="relative" width="100%" justifyContent="center" alignItems="center">
                <Image width="100%" src={ArticlePic} />
                <Text
                    position="absolute"
                    color="white"
                    fontWeight="300"
                    style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '5vw' }}
                >
                    A Cutting Edge Solution to Emergency Action Plan Management
                </Text>
            </Flex>
            <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding="5%">
                <Image width="20%" src={store} />
                <Flex width="75%" direction="column">
                    <Text fontWeight="500" color="#03354c" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '5vw' }}>Store and Access</Text>
                    <Text fontWeight="300" color="#03354c" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>Store all of your organization's EAPS in one convenient place for easy access.</Text>
                </Flex>
            </Flex>
            <Flex
            backgroundColor="#8d99af"
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding="5%">
                <Flex width="75%" direction="column">
                    <Text fontWeight="500" color="white" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '5vw' }}>Share</Text>
                    <Text fontWeight="300" color="white" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>Share EAPs with relevant parties of your institution as well as with visiting teams or temporary medical staff for seamless communication.</Text>
                </Flex>
                <Image width="20%" src={share} />
            </Flex>
            <Flex
            direction="row"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            padding="5%">
                <Image width="20%" src={activate} />
                <Flex width="75%" direction="column">
                    <Text fontWeight="500" color="#03354c" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '5vw' }}>Activate</Text>
                    <Text fontWeight="300" color="#03354c" style={{ fontFamily: 'Helvetica', textAlign: 'center', fontSize: '2vw' }}>Activate the EAP within the app, immediately notifying EAP contacts and 911.</Text>
                </Flex>
            </Flex>
            <Flex
            backgroundColor="#03354c">
                <SupportForm/> 
            </Flex>
        </div>
    )
}
export default HomePage